import axios, { AxiosRequestConfig } from "axios";
import { generateEncryptedKeyBody, generateSEKAndHash } from "../utils/crypto";

const API_URL = "https://getpropapi.appgrowthcompany.com/api/v1/User/";



const encryptedHeaders = generateSEKAndHash() as CommonBody;
const generateEncryptedHeaders = () => {
  const encryptedHeaders = generateSEKAndHash() as CommonBody;
  return {
    hash: encryptedHeaders?.hash,
    sek: encryptedHeaders?.sek,
    deviceType: "WEB",
  };
};
console.log(encryptedHeaders)

const client = axios.create({
  baseURL: API_URL,
  headers: generateEncryptedHeaders(),
});
client.interceptors.request.use(
  (config: any) => {
    // Update headers here before each request if necessary
    config.headers = {
      ...config.headers, // Preserve existing headers
      ...generateEncryptedHeaders(), // Merge with new headers
    } as AxiosRequestConfig['headers']; // Type assertion to AxiosRequestHeaders

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// client.interceptors.request.use(
//   (config) => {
//     // Modify headers here if needed
//     config.headers.hash = generateSEKAndHash()?.hash;
//     config.headers.sek = generateSEKAndHash()?.sek;
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

export default client;