import { useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import TextField from "@mui/material/TextField";
import client from "../../services";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { ToastContainer, toast } from "react-toastify";
import { Box, Modal, Typography } from "@mui/material";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};
const LandingPage = () => {
  const [email,setEmail]=useState<string>("")
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleChange = (e:any) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    // Regex pattern for basic email validation
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    
    if (!emailPattern.test(newEmail)) {
      setError('Invalid email address');
    } else {
      setError('');
    }
  };
  const handleSubmit=async()=>{
    console.log(email)
    
    let body={
      email:email
    }
    if(error || !email){
      if(!email){
        setError("Enter email address")
      }
      return;
    }
    let bodys=generateEncryptedKeyBody(body) as CommonBody
    try{
      const res=await client.post("notifyMe",bodys)
      if(res?.status===200){
        setEmail("")
        setOpen(true)
      }
    }catch(error){
      console.log(error)
    }
  }
  return (
    <div>
      <Header />
      <section className="main_sec">
        <div className="container">
          <div className="main_page">
            <h1>
              1st Real Estate Portal For <br />
              <span> Brand New Homes</span> In The UAE
            </h1>
            <p>Get Ready! We're Launching Soon!</p>
            <div className="register">
              <TextField 
              className="input" label="Email" type="email" value={email} onChange={(e)=>handleChange(e)}/>
             
              <button onClick={handleSubmit}>Notify Me</button>
             
            </div>
            {
                error && (
                  <h6 className="err_msg">{error}</h6>
                )
              }
          </div>
        </div>
      </section>
      
    
      
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            We will get back to you for further notifications
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default LandingPage;
