
const Header=()=>{
    return(
     <header className="head" >
        <div className="container head_cnt">
           <figure className="logo">
            <img src="get-prop-logo.png" alt="logo" />
           </figure>
           <div className="social_icon" >
            <figure>
                <img src="fb.svg" alt="facebook" />
            </figure>
            <figure>
                <img src="insta.svg" alt="instagram" />
            </figure>
            <figure>
                <img src="linkedin.svg" alt="linkedin" />
            </figure>
           </div>
        </div>
        </header>
    )
}

export default Header